"use client";

import { PropsWithChildren } from "react";
import { SessionProvider } from "next-auth/react";
import { ErrorContext } from "src/errors/ErrorContext";

export function Providers(props: PropsWithChildren) {
  return (
    <SessionProvider>
      <ErrorContext>{props.children}</ErrorContext>
    </SessionProvider>
  );
}
