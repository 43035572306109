import { Locale } from "src/helpers/languageHelpers";
import { useEffect, useState } from "react";
import { localizedText } from "src/language/LocalizedTextData";
import { validateEmail } from "./validateEmail";
import { validateWallet } from "./validateWallet";
import { validateNorwegianPhoneNumber } from "./validateNorwegianNumber";
import { getLocale } from "src/helpers/languageHelpers";

type FormFieldValidator = (value: string) => string | null;
export type FormFieldType =
  | "text"
  | "numeric"
  | "email"
  | "phone"
  | "wallet"
  | "float";

export function useFormField(
  initialValue: string,
  type: FormFieldType,
  required: boolean = true
) {
  const [value, _setValue] = useState(initialValue);
  const [error, _setError] = useState<string | null>(null);
  const [isPristine, setIsPristine] = useState(true);
  const locale = getLocale();

  function setError(error: string | null) {
    setIsPristine(false);
    _setError(error);
  }

  function validate(): string | null {
    if (required && !value) {
      const error = localizedText.validationErrors.empty[locale];
      setError(error);
      return error;
    }

    const validator = validators(locale)[type];
    const error = validator(value);

    setError(error);
    return error;
  }

  function setValue(value: string) {
    const filteredValue = inputFilters[type](value);

    _setValue(filteredValue);
  }

  useEffect(() => {
    if (!isPristine && error) {
      validate();
    }
  }, [value]);

  return [value, setValue, error, validate, setError] as const;
}

function validators(locale: Locale): Record<FormFieldType, FormFieldValidator> {
  return {
    text: () => null,
    numeric: () => null,
    float: () => null,
    email: emailValidator(locale),
    phone: phoneValidator(locale),
    wallet: walletValidator(locale),
  };
}

const inputFilters: Record<FormFieldType, (value: string) => string> = {
  text: (value) => value,
  numeric: (value) => value.replace(/\D/g, ""),
  float: (value) => value.replace(/[^0-9]*([0-9]*)(\.?)([0-9]*)/g, "$1$2$3"),
  email: (value) => value,
  phone: (value) => value.replace(/\D/g, ""),
  wallet: (value) => value,
};

export function emailValidator(locale: Locale): FormFieldValidator {
  return (value: string) =>
    validateEmail(value, localizedText.validationErrors.email[locale]);
}

export function walletValidator(locale: Locale): FormFieldValidator {
  return (value: string) =>
    validateWallet(value, localizedText.validationErrors.wallet[locale]);
}

export function phoneValidator(locale: Locale): FormFieldValidator {
  return (value: string) =>
    validateNorwegianPhoneNumber(
      value,
      localizedText.validationErrors.phone[locale]
    );
}
