type ButtonProps = {} & JSX.IntrinsicElements["button"];

export function PrimaryButton(props: ButtonProps) {

  return (
    <button
      type="submit"
      {...props}
      className={`${props.className} min-h-component-height rounded-[0.5rem] bg-primary text-white py-[0.75rem] px-[2rem] font-sans font-medium hover:bg-primary-light focus:bg-primary-light active:bg-primary-dark disabled:pointer-events-none disabled:opacity-50`}
    />
  );
}
