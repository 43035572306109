import { PropsWithChildren } from "react";

export default function RoundedButton(
  props: PropsWithChildren & JSX.IntrinsicElements["button"]
) {
  const bgColor = props.className?.includes("bg-") ? "" : "bg-primary";

  return (
    <button
      {...props}
      className={
        props.className +
        "w-fit max-w-[15rem] rounded-[0.5rem] border py-2 px-6 text-lg text-white hover:underline disabled:opacity-50 " +
        bgColor
      }
    >
      {props.children}
    </button>
  );
}
