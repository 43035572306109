import styles from "./Spinner.module.scss";

export type SpinnerProps = JSX.IntrinsicElements["svg"];

export function Spinner(props: SpinnerProps) {
  const { className, ...rest } = props;

  return (
    <svg
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      className={`${styles.spinner} ${className} stroke-primary`}
    >
      <circle cx="50" cy="50" r="45" />
    </svg>
  );
}
