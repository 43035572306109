import { ReactNode } from "react";
import { cn } from "./utils";

export type TextInputProps = {
  type: "text" | "email" | "password" | "number" | "tel" | "url" | "readOnly";
  label?: string;
  helpText?: string | ReactNode;
  error?: string | ReactNode | null;
  errorText?: string;
  width?: "full" | "fit";
  icon?: ReactNode;
} & JSX.IntrinsicElements["input"];

export function TextInput(props: TextInputProps) {
  const textColor = props.error
    ? "text-danger placeholder:text-danger"
    : "text-text placeholder:text-text";

  const borderColor = props.error
    ? "border-danger"
    : "border-text hover:border-primary focus:border-primary";

  const textOpacity = props.type === "readOnly" ? "text-opacity-50" : "";

  const width = props.width === "full" ? "w-full" : "w-fit";

  const { helpText, icon, ...inputProps } = props;

  const textInputIcon = !props.icon ? null : (
    <div
      className={`${borderColor} ${textColor} ${textOpacity} align-center flex h-component-height justify-center rounded rounded-l-none border bg-[#fff] font-sans text-sm font-normal outline-none focus:border-2`}
    >
      {props.icon}
    </div>
  );

  return (
    <div className={`flex ${width} flex-col`}>
      {props.label && (
        <label
          className={`mb-1 font-sans text-sm font-medium ${textColor}`}
          id={`${props.id}-label`}
          htmlFor={props.id}
        >
          {props.label}
        </label>
      )}
      <div className="flex">
        <input
          readOnly={props.type === "readOnly"}
          className={cn(
            "h-component-height w-full rounded border px-3 font-sans text-sm font-normal outline-none placeholder:opacity-50 focus:border-2",
            borderColor,
            textColor,
            textOpacity,
            props.icon ? ["rounded-r-none", "border-r-0"] : null
          )}
          {...inputProps}
        />
        {textInputIcon}
      </div>
      {helpText && !props.error && (
        <label
          className={`mt-2 font-sans text-sm font-normal ${textColor}`}
          id={`${props.id}-help-text`}
          htmlFor={props.id}
        >
          {helpText}
        </label>
      )}
      {props.error && (
        <label
          className="mt-2 font-sans text-sm font-bold text-danger"
          id={`${props.id}-help-text`}
          htmlFor={props.id}
        >
          {props.error}
        </label>
      )}
    </div>
  );
}
