"use client";
import { Modal } from "src/components/Modal";
import { WaitingListForm } from "./WaitingListForm";
import { useState } from "react";
import { PrimaryButton } from "src/components/PrimaryButton";

type WaitingListModalProps = {
  buttonClass?: string;
  font?: string;
  show: boolean;
  onClose: () => void;
};

export function WaitingListModalButton(props: JSX.IntrinsicElements["button"]) {
  const [show, setShow] = useState(false);

  return (
    <>
      <PrimaryButton onClick={() => setShow(true)} {...props}>
        {"Join waitlist"}
      </PrimaryButton>

      <WaitingListModal show={show} onClose={() => setShow(false)} />
    </>
  );
}

export function WaitingListModal(props: WaitingListModalProps) {
  const { buttonClass, font, ...modalProps } = props;
  return (
    <Modal {...modalProps}>
      <WaitingListForm buttonClass={buttonClass} font={font} />
    </Modal>
  );
}
