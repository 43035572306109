"use client";

import { useState } from "react";
import { K33Navigation } from "src/components/K33/K33Navigation";
import { WaitingListModal } from "src/waiting-list/components/WaitingListModal";
import Image from "next/image";

export interface K33HeaderProps {
  disableLaunchAppButton: boolean;
}

export function K33Header(props: K33HeaderProps) {
  return (
    <div className="relative flex h-fit w-full flex-row items-center justify-center bg-[#1e1e24]">
      <div
        id="header-padding"
        className="flex w-full max-w-5xl flex-col items-center lg:max-w-6xl"
      >
        <K33Navigation />
        <div className="md:gap-15 flex w-full flex-col-reverse items-center justify-between gap-10 px-4 py-10 sm:px-7 md:flex-row md:py-36 xl:px-0">
          <div className="flex h-auto w-full flex-col items-start gap-10 text-[#f5f6fc] md:w-1/2 md:gap-14 lg:w-2/3">
            <div>
              <h1 className="max-w-xl text-start font-manrope text-[2.5rem] font-bold leading-[1.1em] sm:text-[2rem] md:text-[4rem]">
                Get cash without selling your crypto
              </h1>
            </div>
            <p className="flex max-w-sm flex-col text-start font-manrope text-lg text-[#bcbfcc] md:max-w-md">
              K33 helps you get a crypto-backed loan. Use Bitcoin (BTC) or Ether
              (ETH) as collateral, receive a loan in a USD stablecoin and
              convert it to NOK, EUR, USD + 5 more.
            </p>
            <div className="flex w-full flex-row justify-start gap-2 sm:gap-5 lg:gap-10">
              <K33JoinWaitlistButton />
              {!props.disableLaunchAppButton && <K33LaunchAppButton />}
            </div>
          </div>
          <Image
            className="left-[50%] py-5 md:py-0 xl:hidden"
            width={655}
            height={655}
            src="/chrome11.png"
            alt="K33 Logo"
          />
        </div>
      </div>
      <Image
        className="absolute top-60 left-[50%] hidden overflow-hidden xl:flex"
        width={645}
        height={645}
        src="/chrome11.png"
        alt="K33 Logo"
      />
    </div>
  );
}

function K33JoinWaitlistButton() {
  const [show, setShow] = useState(false);
  return (
    <>
      <button
        onClick={() => setShow(true)}
        className="flex h-20 w-fit flex-row items-center justify-between gap-5 rounded-md bg-[#a54242] px-10 font-manrope font-semibold text-white transition-colors duration-300 ease-in-out 
          hover:bg-[#803333] focus:bg-[#803333] sm:w-64 sm:pl-10 sm:pr-5"
      >
        Join Waitlist
        <div className="hidden h-3/5 w-14 flex-row items-center justify-between sm:flex">
          <div className="h-full w-[0.05rem] bg-white" />
          <div>
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.5077 5.16919L13.6856 6.99134L20.8966 14.2153H5.16925V16.8H20.8966L13.6856 24.024L15.5077 25.8461L25.8462 15.5077L15.5077 5.16919Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
      </button>
      <WaitingListModal
        show={show}
        onClose={() => setShow(false)}
        font="font-manrope"
        buttonClass="bg-[#a54242] hover:bg-[#803333] font-manrope focus:bg-[#803333] "
      />
    </>
  );
}

function K33LaunchAppButton() {
  return (
    <a href="/auth/signin">
      <button className=" h-20 w-fit gap-5 rounded-md bg-[#f5f6f7]  px-10 font-manrope font-semibold text-black transition-colors duration-300 ease-in-out hover:bg-gray-300 focus:bg-gray-300 sm:whitespace-nowrap md:w-52">
        Launch App
      </button>
    </a>
  );
}
